<template>
  <div id="dShop">
    <div class="dShop_Search">
      <van-search
        v-model="searchValue"
        @search="search"
        placeholder="请输入商品名称"
        :left-icon="require('@image/shop/shop_search_icon.png')"
      />
    </div>

    <div class="dShop_Left">
      <swiper
        ref="Left_Swiper"
        :options="leftTitle"
        class="swiper-container-left"
        style="z-index: 9999"
      >
        <swiper-slide ref="left_Pos_Wrap">
          <ul class="dShop_Left-ul">
            <li
              @click="checkedClassList(item.id, item.value)"
              :class="{ act: item.id == classListId }"
              v-for="item in listData"
              :key="item.id"
            >
              {{ item.value }}
            </li>
            <li></li>
          </ul>
        </swiper-slide>
      </swiper>
    </div>

    <div class="dShop_Right">
      <swiper
        ref="Right_Swiper"
        :options="rightText"
        class="swiper-container-right"
      >
        <swiper-slide>
          <h1>
            <img src="@image/shop/shop_index_border.png" />
            {{ classListName }}
            <img src="@image/shop/shop_index_border.png" />
          </h1>
          <oProductList
            :productData="productData"
            @flush="flushCart"
            :cartGoods="cartGoods"
          >
            <template slot-scope="data">
              <!-- 加入购物车 -->
              <template v-if="getCartNum(data.id) != 0">
                <img
                  src="@image/shop/shop_action_subtract.png"
                  @click.stop="subtract(data.id, data.index)"
                />
                <b>{{ getCartNum(data.id) }}</b>
              </template>
              <template>
                <img
                  v-if="data.skunum == 1"
                  src="@image/shop/shop_action_add.png"
                  @click.stop="addcart(data)"
                />

                <img v-else src="@image/shop/shop_action_add.png" />
              </template>

              <!-- 加入购物车 -->
            </template>
          </oProductList>
        </swiper-slide>
      </swiper>
    </div>

    <div class="dShop_cart flex-container">
      <div class="cart" @click="cartBoo = true">
        <img src="@image/shop/shop_cart.png" />
        <div class="num" v-if="cartGoods.length != 0">
          {{ cartGoods.length }}
        </div>
      </div>
      <div class="price flex1">
        总计：<b>￥{{ getCartPrice() }}</b>
      </div>
      <div class="pay" @click="goPayStep1()">结算</div>
    </div>

    <oCart
      :boo.sync="cartBoo"
      :cartData="cartGoods"
      :payPrice="getCartPrice()"
      @step1="goPayStep1"
    ></oCart>
  </div>
</template>

<script>
import { post } from "@get/http.js";
import oCart from "./componetns/cart";
import oProductList from "./componetns/productList";
import {
  getCartDel,
  getCartAdd,
  getCartList,
  getCartQuantity,
} from "@view/cart/js/";
import { Search, Dialog, Toast } from "vant";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { getStoreGoodsClass } from "./js";
import { getQueryVariable } from "@get/util.js";
export default {
  components: {
    vanSearch: Search,
    oCart,
    oProductList,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      rightText: {
        freeMode: true,
        mousewheel: true,
        resistanceRatio: 0,
        direction: "vertical",
        nested: true,
        slidesPerView: "auto",
        freeMode: true,
        scrollbar: {
          el: ".swiper-scrollbar",
        },
        on: {
          touchEnd: function() {
            if (this.translate > 50) {
              // console.log("向下拉");
            }
            if (this.snapGrid[1] * -1 > this.translate + 70) {
              // console.log("向上拉");
            }
          },
        },
      },
      leftTitle: {
        direction: "vertical",
        slidesPerView: "auto",
        freeMode: true,
        mousewheel: true,
        resistanceRatio: 0,
      },
      cartGoods: [],
      cartBoo: false,
      heights: "120px",
      productData: [], //产品数据
      listData: [], //二级分类数据
      searchValue: "",
      classListId: "",
      classListName: "",
      store_id: false,
    };
  },
  mounted() {
    let store_id = getQueryVariable("store_id") || false;
    if (store_id) {
      this.store_id = store_id;
      this.getGoodsclassList();
      this.getCart();
    } else {
      alert("非法进入");
      // this.$router.push({
      //   name: "New_404",
      // });
    }
  },
  methods: {
    search() {
      this.getGoodsList("", 5, this.searchValue);
    },
    goPayStep1() {
      let c = this.cartGoods;
      if (c.length == 0) {
        return false;
      }
      let c_arr = "";
      for (let index = 0; index < c.length; index++) {
        c_arr += `${c_arr.length != 0 ? "," : ""}${c[index].cart_id}|${
          c[index].buy_num
        }`;
      }
      this.$router.push({
        name: "Buy",
        query: {
          cart_id: c_arr,
          store_id: this.store_id,
        },
      });
    },
    subtract(goods_id, index) {
      let num = this.getCartNum(goods_id);
      let cart_id = this.getCartNum(goods_id, "cart_id");
      //减购物车数量
      num--;
      if (num == 0) {
        Dialog.confirm({
          message: "确定要删除这个产品吗",
        }).then((res) => {
          getCartDel(cart_id).then((res) => {
            this.cartGoods.splice(this.getCartNum(goods_id, "index"), 1);
            Toast({
              message: "删除成功",
              forbidClick: true,
              duration: 1000,
            });
          });
        });
      } else {
        Toast({
          message: "成功",
          forbidClick: true,
          duration: 1000,
        });
        this.setUpdateCart(cart_id, goods_id, num);
      }
    },
    async setUpdateCart(cart_id, goods_id, num) {
      // 更新购物车数量
      let result = await getCartQuantity(cart_id, num);
      this.cartGoods[this.getCartNum(goods_id, "index")].buy_num = result;
    },
    flushCart(val) {
      // 刷新购物车.
      this.getCart();
    },
    getCartPrice() {
      //获取购物车的总价格
      let c = this.cartGoods;
      let c_len = c.length;
      let p = 0;
      if (c_len == 0) {
        return p;
      } else {
        for (let index = 0; index < c_len; index++) {
          p += parseFloat(c[index].goods_price) * c[index].buy_num;
        }

        return p.toFixed(2);
      }
    },
    getCartNum(id, type = "buy_num") {
      // 获得购物车选中值

      for (let index = 0; index < this.cartGoods.length; index++) {
        if (this.cartGoods[index].goods_id == id) {
          if (type == "index") {
            return index;
          }

          return this.cartGoods[index][type];
        }
      }
      return 0;
    },
    checkedClassList(id, val) {
      this.classListId = id;
      this.classListName = val;
      this.getGoodsList(id);
    },
    async getGoodsclassList() {
      let result = await getStoreGoodsClass(this.store_id);

      this.listData = result.store_goods_class;
      this.checkedClassList(this.listData[0].id, this.listData[0].value);
    },

    async addcart(data) {
      try {
        let findData = this.cartGoods.find((item) => item.goods_id == data.id);
        let num = this.getCartNum(data.id);
        if (findData) {
          await this.setUpdateCart(findData.cart_id, findData.goods_id, ++num);
        } else {
          await getCartAdd(data.id, ++num);
        }

        this.flushCart();
        Toast({
          message: "加入成功",
          forbidClick: true,
          duration: 500,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getCart() {
      //获取购物车数据

      let result = await getCartList(this.store_id);

      this.cartGoods = result;
    },

    async getGoodsList(id, sort = 5, keyword = "") {
      Toast.loading({
        duration: 0,
        message: "获取中",
        forbidClick: true,
      });
      let { goods_list } = await post("/Store/store_goods", {
        data: {
          page: 1, //页数
          storegc_id: id, // 二级分类ID
          keyword,
          store_id: this.store_id, //店铺ID
          sort_order: "", //  按低到高排序为1
          sort_key: sort, //  按什么排序
          //5是默认     价格降序是2  升序 1,2   销量降序是 3  销量升序是 1
        },
      });
      this.productData = goods_list;
      if (keyword) {
        this.classListId = -1;
        this.classListName = "搜索结果";
      }
      Toast.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.swiper-container {
  margin: 0;
  width: 100%;
  height: 100%;
}
.swiper-slide {
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.dShop_Search {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 999;
}

#dShop {
  background: #fff;
  min-height: 100%;
}
/deep/ {
  #app {
    height: 100%;
  }
  $search_height: 72;
  .van-icon {
    line-height: 1em;
  }
  .van-search__content {
    height: rem($search_height);
    border-radius: rem($search_height/2);
    padding: 0 rem(32);
  }
  .van-field__control {
    text-indent: rem(10);
  }
}

.dShop_Left {
  width: rem(160);
  height: calc(100% - 2.94737rem);
  // overflow-y: auto;
  // overflow-x: hidden;
  // -webkit-overflow-scrolling: touch;
  position: fixed;
  top: rem(112);
  left: 0;
  background: #f6f6f6;
}
.dShop_Left-ul {
  height: 80%;

  z-index: 9999;
  .act {
    font-weight: bold;
    background: #fff;
  }
  li {
    width: 100%;
    height: rem(122);
    line-height: rem(122);
    text-align: center;
    font-size: rem(32);
  }
}

.dShop_Right {
  padding-bottom: rem(160);
  position: fixed;
  // top: rem(112);
  // left: rem(160);
  top: 0;
  left: 0;

  background: #fff;
  // overflow-y: auto;
  // overflow-x: hidden;
  // width: calc(100% - 3.15789rem);
  // height: calc(100% - 4.21053rem);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: rem(160);
  padding-top: rem(120);
  z-index: -1;
  h1 {
    text-align: center;
    margin-top: rem(20);
    img {
      width: rem(26);
      height: rem(20);
    }
    font-size: rem(34);
  }
}

.dShop_cart {
  align-items: center;
  background: rgba(40, 38, 38, 0.86);
  backdrop-filter: blur(rem(32));
  border-radius: rem(24);
  width: rem(690);
  height: rem(100);
  margin: auto;
  position: fixed;
  bottom: rem(30);
  left: 0;
  right: 0;
  .cart {
    position: absolute;
    top: rem(-20);
    left: rem(20);
    img {
      width: rem(86);
      height: rem(86);
    }
    .num {
      background: #fb7e1c;
      border: rem(2) solid #ffffff;
      box-sizing: border-box;
      width: rem(26);
      height: rem(26);
      position: absolute;
      right: 0;
      top: rem(1);
      border-radius: 50%;
      line-height: rem(26);
      text-align: center;
      color: #fff;
      font-size: rem(20);
    }
  }
  .price {
    color: #ffffff;
    font-size: rem(26);
    margin-left: rem(126);
    b {
      font-size: rem(34);
      font-weight: bold;
    }
  }
  .pay {
    width: rem(220);
    height: rem(80);
    background: #ff4848;
    border-radius: rem(40);
    margin-right: rem(20);
    color: #ffffff;
    font-size: rem(34);
    text-align: center;
    line-height: rem(80);
    font-weight: bold;
  }
}
</style>
