<template>
  <div>
    <ul class="dShop_Right-ul">
      <li
        class="flex-container"
        v-for="(item, index) in productData"
        :key="item.goods_id"
        @click="type == 'default' ? ShowSku(item.goods_id, item.sky_num) : ''"
      >
        <img
          :src="item.goods_image_url || item.goodsimage_full_url"
          class="mainImg"
        />
        <div class="data">
          <div class="title">
            <!-- <img src="@dajiao/shop/Rectangle 10.png" class="tip" /> -->
            {{ item.goods_name }}
          </div>
          <span class="subTitle">&nbsp;</span>
          <div class="price flex-container">
            <span>￥{{ setPrice(item.goods_price) }}</span>
            <i
              v-if="
                item.goods_promotion_price != item.goods_price &&
                  setPrice(item.goods_price).length < 6
              "
              >￥{{ setPrice(item.goods_price) }}</i
            >
            <div class="action flex-container flex1">
              <slot
                :id="item.goods_id"
                :cartid="item.cart_id"
                :index="index"
                :skunum="item.sky_num"
              />
            </div>
          </div>
        </div>
      </li>
    </ul>

    <oSku
      :boo.sync="skuBoo"
      :id="goods_id"
      @flush="fulshCart"
      :cartGoods="cartGoods"
    ></oSku>
  </div>
</template>

<script>
import oSku from "../sku";
export default {
  components: {
    oSku,
  },
  props: {
    productData: {},
    type: {
      default: "default",
      type: String,
    },
    cartGoods: {},
  },
  data() {
    return {
      skuBoo: false,
      goods_id: "",
    };
  },
  methods: {
    fulshCart(val) {
      this.$emit("flush", val);
    },
    subtract() {
      this.$emit("subtract", "subtract");
    },
    add() {
      this.$emit("add", "add");
    },
    ShowSku(id, sku_num) {
      this.goods_id = id;
      this.skuBoo = true;
      // if (sku_num > 1) {

      // } else {
      //   console.log("加入购物车 ", sku_num);
      // }
    },
    setPrice(val) {
      let arr = toString(val).split(".");
      if (arr[1] == "00") {
        return arr[0];
      } else {
        return val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.dShop_Right-ul {
  width: 94%;

  li {
    width: 100%;
    height: rem(200);
    align-items: center;

    .mainImg {
      width: rem(140);
      height: rem(140);
      border-radius: rem(8);
      margin: 0 rem(20);
    }
    .data {
      width: 100%;
      @include ell;
      .tip {
        display: inline-block;
        width: rem(58);
        height: rem(35);
        margin-right: rem(10);
      }
      .title {
        @include ell;
        font-size: rem(30);
        width: 93%;
        font-weight: bold;
      }
      .subTitle {
        display: block;
        font-size: rem(28);
        color: #999;
        padding-bottom: rem(11);
      }
      .price {
        span {
          display: inline-block;
          color: #fb7e1c;
          font-size: rem(34);
          padding-right: rem(6);
          // align-self: center;
        }
        i {
          text-decoration-line: line-through;
          color: #c9c9c9;
          font-size: rem(26);
          // align-self: center;
          line-height: rem(56);
        }
      }
    }
    .action {
      justify-content: flex-end;
      margin-right: rem(30);

      img {
        display: block;
        width: rem(44);
        height: rem(44);
      }
      b {
        color: #333;
        font-size: rem(30);
        margin: 0 rem(14);
      }
    }
  }
}
</style>
