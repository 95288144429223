<template>
  <div>
    <van-popup
      safe-area-inset-bottom
      v-model="skuShow"
      get-container="#app"
      :style="{ height: '70%' }"
      round
      position="bottom"
    >
      <div class="skuData">
        <div class="sku_header flex-container">
          <img
            @click="
              getZoomImage(
                thisSkuData.goods_image ||
                  allData.goods_image[0].goodsimage_full_url
              )
            "
            :src="
              thisSkuData.goods_image ||
                (allData &&
                  allData.goods_image &&
                  allData.goods_image[0].goodsimage_full_url)
            "
          />
          <div class="data">
            <h1>{{ productData.goods_name }}</h1>
            <template>
              <span v-if="productData.goods_id == thisSkuData.goods_id">
                已选择 {{ productData.goods_name }}
              </span>
              <span v-else>请选择 规格</span>
            </template>
            <p>￥{{ thisSkuData.goods_price || productData.goods_price }}</p>
          </div>
        </div>
        <div class="sku-wrap">
          <h2>规格</h2>
          <ul class="sku-ul flex-container">
            <li
              class="flex-container"
              @click="checkedSku(item)"
              v-for="item in productData.goods_spec2"
              :key="item.goods_id"
              :class="{ act: item.goods_id == thisSkuData.goods_id }"
            >
              <div class="skuimg">
                <img :src="item.goods_image_path || allData.spec_image[0]" />
              </div>
              <h3>{{ item.spvalue_name }}</h3>
            </li>
          </ul>
        </div>
        <div class="add-cart flex-container">
          <div class="action flex-container flex1">
            <img src="@image/shop/shop_action_subtract.png" @click="subtract" />
            <b>{{ num }}</b>
            <img src="@image/shop/shop_action_add.png" @click="add" />
          </div>
          <template>
            <div
              class="add"
              @click="joinCart"
              v-if="productData.goods_storage != 0 && thisSkuData.goods_price"
            >
              加入购物车
              <span
                >￥{{
                  thisSkuData.goods_price
                    ? thisSkuData.goods_price * this.num
                    : 0
                }}</span
              >
            </div>

            <div
              class="add"
              v-if="productData.goods_storage == 0 && thisSkuData.goods_price"
              style="opacity: 0.3"
            >
              没有库存了
            </div>
          </template>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup, Toast, ImagePreview } from "vant";
import oProductList from "../productList/";
import {
  getCartDel,
  getCartAdd,
  getCartList,
  getCartQuantity,
} from "@view/cart/js/";
import { getGoodsDetail } from "@view/home/view/goods/js/";

export default {
  props: {
    id: {},
    boo: {
      defalut: false,
      type: Boolean,
    },
    cartGoods: {},
  },
  watch: {
    id() {
      if (this.id != this.productData.goods_id) {
        this.thisSkuData = {};
      }
      this.getData(this.id);
    },
    boo() {
      this.skuShow = this.boo;
    },
    skuShow() {
      this.$emit("update:boo", this.skuShow);
      this.num = 1;
    },
  },
  components: {
    vanPopup: Popup,
  },
  created() {
    // this.getData(this.id);
    // this.skuID =
    this.skuShow = this.boo;
  },
  data() {
    return {
      skuShow: false,
      skuID: "",
      productData: {},
      allData: {},
      num: 1,
      thisSkuData: {}, //当前选中SKU的数据
    };
  },
  methods: {
    async joinCart() {
      let findData = this.getFindCartGoodsId();
      if (findData) {
        await getCartQuantity(findData.cart_id, this.num);
      } else {
        await getCartAdd(this.thisSkuData.goods_id, this.num);
      }
      this.skuShow = false;
      this.$emit("flush", "flush");
      Toast("加入成功");
    },
    subtract() {
      if (this.num == 1) {
        Toast("不能再少了~");
      } else {
        this.num--;
      }
    },
    add() {
      if (this.num >= this.productData.goods_storage) {
        Toast(`只剩${this.productData.goods_storage}个了,不能再加了~`);
      } else {
        this.num++;
      }
    },
    getFindCartGoodsId() {
      return this.cartGoods.find(
        (item) => item.goods_id == this.thisSkuData.goods_id
      );
    },
    async getData(id, item) {
      Toast.loading({
        duration: 0,
        message: "获取中",
        forbidClick: true,
      });
      if (!id) {
        return false;
      }

      let { result } = await getGoodsDetail(id);
      this.allData = result;
      this.productData = result.goods_info;
      this.thisSkuData = {
        // 选中sku
        goods_id: this.productData.goods_id,
        goods_name: this.productData.spvalue_name,
        goods_image:
          item?.goods_image_path ||
          this.productData.goods_spec2[0].goods_image_path,
        goods_price: this.productData.goods_price,
      };

      let findData = this.getFindCartGoodsId();
      if (findData) {
        this.num = findData.buy_num;
      }
      Toast.clear();
    },
    getZoomImage(image) {
      ImagePreview([image]);
    },
    async checkedSku(item) {
      if (item.goods_id == this.thisSkuData.goods_id) {
        this.getZoomImage(item.goods_image_path);
        return false;
      }
      // 选中sku
      await this.getData(item.goods_id, item);

      // if (this.productData.goods_id != item.goods_id) {
      //   await this.getData(item.goods_id);

      //   this.thisSkuData = {
      //     goods_id: item.goods_id,
      //     goods_name: item.spvalue_name,
      //     goods_image: item.goods_image_path,
      //     goods_price: this.productData.goods_price,
      //   };
      // } else {
      //   this.thisSkuData = {
      //     goods_id: item.goods_id,
      //     goods_name: item.spvalue_name,
      //     goods_image: item.goods_image_path,
      //     goods_price: this.productData.goods_price,
      //   };
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.skuData {
  .sku_header {
    box-sizing: border-box;
    padding: rem(40) rem(30);
    img {
      width: rem(160);
      height: rem(160);
      border-radius: rem(8);
      margin-right: rem(20);
    }
    .data {
      width: 70%;

      h1 {
        font-size: rem(34);
        font-weight: bold;
        color: #333;
        margin-bottom: rem(6);
        @include ell;
      }
      span {
        font-size: rem(28);
        color: #999;
        @include ell;
        // display: inline-block;
        margin-bottom: rem(20);
      }
      p {
        font-size: rem(38);
        color: #fb7e1c;
        font-weight: bold;
      }
    }
    margin-bottom: rem(30);
  }
  .sku-wrap {
    h2 {
      color: #333333;
      font-size: rem(32);
      font-weight: bold;
      margin-left: rem(30);
      margin-bottom: rem(20);
    }
    .sku-ul {
      flex-wrap: wrap;
      padding-bottom: rem(140);
      .act {
        h3 {
          color: #ff4848;
        }
        .skuimg {
          position: relative;
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 99%;
            height: 98%;
            border: 1px solid #ee0a24;
            content: "";
            border-radius: rem(8);
          }
        }
      }
      li {
        flex-direction: column;
        align-items: center;
        margin-bottom: rem(30);
        width: rem(250);
        img {
          width: rem(96);
          height: rem(96);
          border-radius: rem(8);
        }

        h3 {
          margin-top: rem(10);
          text-align: center;
          color: #999;
          font-size: rem(26);
          width: rem(180);
          @include ell2;
        }
      }
    }
  }
  .add-cart {
    position: fixed;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: rem(140);
    background: #fff;
    box-sizing: border-box;
    padding: 0 rem(30);
    border-top: 1px solid #efefef;
    .action {
      // justify-content: flex-end;
      margin-right: rem(30);

      img {
        width: rem(44);
        height: rem(44);
      }
      b {
        color: #333;
        font-size: rem(30);
        margin: 0 rem(14);
      }
    }
    .add {
      font-size: rem(34);
      color: #fff;
      text-align: center;
      line-height: rem(80);
      border-radius: rem(40);
      width: rem(342);
      height: rem(80);
      background: #ff4848;
    }
  }
}
</style>
