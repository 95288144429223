import {
  post
} from '@get/http'

export const getStoreGoodsClass = (store_id) => {
  return post('/Store/store_goods_class', {
    data: {
      store_id
    }
  })
}