<template>
  <div>
    <van-popup
      safe-area-inset-bottom
      v-model="cartShow"
      class="cart_wrap"
      get-container="body"
      :style="{ height: '70%' }"
      round
      position="bottom"
    >
      <oProductList :productData="cartData" type="cart">
        <template slot-scope="data">
          <!-- 加入购物车 -->
          <template v-if="getCartNum(data.id) != 0">
            <img
              src="@image/shop/shop_action_subtract.png"
              @click="subtract(data.cartid, getCartNum(data.id), data.index)"
            />
            <b>{{ getCartNum(data.id) }}</b>
          </template>
          <img
            src="@image/shop/shop_action_add.png"
            @click="add(data.cartid, getCartNum(data.id), data.index)"
          />
          <!-- 加入购物车 -->
        </template>
      </oProductList>

      <div class="dShop_cart flex-container">
        <div class="cart" @click="cartBoo = true">
          <img src="@image/shop/shop_cart.png" />
          <div class="num">{{ cartData.length }}</div>
        </div>
        <div class="price flex1">
          总计：<b>￥{{ payPrice }}</b>
        </div>
        <div class="pay" @click="$emit('step1', true)">结算</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup, Dialog, Toast } from "vant";
import oProductList from "../productList/";
import {
  getCartDel,
  getCartAdd,
  getCartList,
  getCartQuantity,
} from "@view/cart/js/";
import { post } from "@get/http.js";
export default {
  props: {
    boo: {
      defalut: false,
      type: Boolean,
    },
    payPrice: {},
    cartData: {},
  },
  watch: {
    boo() {
      this.cartShow = this.boo;
    },
    cartShow() {
      this.$emit("update:boo", this.cartShow);
    },
  },
  components: {
    vanPopup: Popup,
    oProductList,
  },
  created() {
    this.cartShow = this.boo;
  },
  data() {
    return {
      cartShow: false,
    };
  },
  methods: {
    async subtract(id, num, index) {
      //减购物车数量
      num--;
      if (num == 0) {
        Dialog.confirm({
          message: "确定要删除这个产品吗",
        }).then(async (res) => {
          await getCartDel(id);
          this.cartData.splice(index, 1);
        });
      } else {
        this.setUpdateCart(id, num, index);
      }
    },
    add(id, num, index) {
      //赠购物车数量
      num++;
      this.setUpdateCart(id, num, index);
    },
    async setUpdateCart(id, num, index) {
      // 更新购物车数量
      let result = await getCartQuantity(id, num);

      this.cartData[index].buy_num = result;
    },

    getCartNum(id) {
      // 获得购物车选中值
      for (let index = 0; index < this.cartData.length; index++) {
        if (this.cartData[index].goods_id == id) {
          return this.cartData[index].buy_num;
        }
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
/deep/ .mainImg {
  margin-left: rem(30) !important;
}
/deep/ .dShop_Right-ul {
  width: 100%;
  padding-bottom: rem(160);
}

.dShop_cart {
  align-items: center;
  background: rgba(40, 38, 38, 0.86);
  backdrop-filter: blur(rem(32));
  border-radius: rem(24);
  width: rem(690);
  height: rem(100);
  margin: auto;
  position: fixed;
  bottom: rem(30);
  left: 0;
  right: 0;
  .cart {
    position: absolute;
    top: rem(-20);
    left: rem(20);
    img {
      width: rem(86);
      height: rem(86);
    }
    .num {
      background: #fb7e1c;
      border: rem(2) solid #ffffff;
      box-sizing: border-box;
      width: rem(26);
      height: rem(26);
      position: absolute;
      right: 0;
      top: rem(1);
      border-radius: 50%;
      line-height: rem(26);
      text-align: center;
      color: #fff;
      font-size: rem(20);
    }
  }
  .price {
    color: #ffffff;
    font-size: rem(26);
    margin-left: rem(126);
    b {
      font-size: rem(34);
      font-weight: bold;
    }
  }
  .pay {
    width: rem(220);
    height: rem(80);
    background: #ff4848;
    border-radius: rem(40);
    margin-right: rem(20);
    color: #ffffff;
    font-size: rem(34);
    text-align: center;
    line-height: rem(80);
    font-weight: bold;
  }
}
</style>
